<template>
    <div>
        <el-row>
            <el-col :span="24" style="float: left">
                <span style="font-size: 24px"><center>商家入驻总数 :{{ all }} 家</center></span>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div id="container">
                    <!-- 存放图形的div -->
                    <div id="storeEcharts"></div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>

    export default {
        name: "MerchantEcharts",
        data() {
            return {
                all: 0,
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.initChar();
            },
            initChar() {
                this.$http.get("/salemanagers/echarts/merchant").then((res => {
                    //统计总数
                    this.all = res.data.data.counts;

                    const myChart = this.$echarts.init(document.getElementById('storeEcharts'));
                    myChart.setOption({
                        title: {
                            text: "商家入驻数统计图",
                        },
                        //提示信息
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['入驻数']
                        },
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: {
                            data: res.data.data.xdatas
                        },
                        yAxis: {},
                        series: [
                            {
                                name: "入驻数",
                                type: 'line',
                                data: res.data.data.ydatas,
                                label: {
                                    show: true,
                                    position: 'top',
                                    textStyle: {
                                        fontSize: 15
                                    }
                                },
                                areaStyle: {
                                    color: '#8195d3',
                                    opacity: 0.5
                                }
                            }
                        ]
                    })
                }))
            },

        }
    }
</script>
<style scoped>
    #storeEcharts {
        height: 500px;
        width: 900px;
    }

    #container {
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
    }
</style>