<template>
    <div>
        <div id="app">
            <div id="searchData">
                <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                    <el-form-item>
                        <el-input v-model="searchForm.storeName" placeholder="——请按照店名搜索——"
                                  class="center-text"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="success" @click="searchStore" icon="el-icon-search">搜索</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addCouponCodes" icon="el-icon-circle-plus-outline">添加</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="flushedCouponCodes" icon="el-icon-refresh">刷新</el-button>
                    </el-form-item>
                </el-form>
            </div>

            <div id="myTable">
                <el-table
                        :data="tableData"
                        height="500"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="couponId"
                            width="100"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="store.storeName"
                            label="所属门店"
                            width="160"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="couponName"
                            label="优惠券名称"
                            width="120"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="couponType"
                            label="优惠券类型"
                            width="120"
                    > </el-table-column>

                    <el-table-column
                            prop="discountAmount"
                            label="满减/返现金额(元)"
                            width="150"
                            :formatter="getdiscountAmount"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="discountPercentage"
                            label="折扣百分比(%)"
                            width="120"
                            :formatter="getdiscountPercentage"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="minimumOrderAmount"
                            label="使用优惠券所需要的最低订单金额"
                            width="120"
                            :formatter="getminimumOrderAmount"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="startDate"
                            label="优惠券生效时间"
                            width="160"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="endDate"
                            label="优惠券过期时间"
                            width="160"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="usageLimit"
                            label="优惠券总数量"
                            width="120"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="usageCount"
                            label="优惠券已使用数量"
                            width="160"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="isUsed"
                            label="是否已使用"
                            width="160"
                            :formatter="getisUsed"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="creationDate"
                            label="优惠券创建时间"
                            width="160"
                    >
                    </el-table-column>
                    <el-table-column
                            label="操作"
                            width="270"
                            fixed="right">
                        <template slot-scope="scope">
                            <el-button type="primary" @click="editCouponCodes(scope.row)" icon="el-icon-edit-outline">编辑
                            </el-button>
                            <el-button type="danger" @click="deleteCouponCodes(scope.row.couponId)"
                                       icon="el-icon-remove-outline">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div id="pagination">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[1, 5, 10, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>

            <div id="dialog">
                <!--添加和编辑的对话框-->
                <el-dialog
                        title="提示"
                        :visible.sync="dialogVisible"
                        width="40%"
                        :before-close="handleClose">
                    <el-form ref="addEditform" :model="addEditform" :rules="rules" label-width="80px">
                        <el-form-item
                                label="优惠券名称"
                                :label-width="formLabelWidth"
                                prop="couponName"
                        >
                            <div class="left">
                                <el-input
                                        v-model="addEditform.couponName"
                                        autocomplete="off"
                                        placeholder="请输入优惠券名称"
                                ></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item
                                label="所属门店"
                                :label-width="formLabelWidth"
                                prop="storeId"
                        >
                            <div class="left">
                                <el-select
                                        v-model="addEditform.storeId"
                                        placeholder="请选择门店"
                                        style="width:100%"
                                >
                                    <el-option
                                            v-for="option in stores"
                                            :key="option.storeId"
                                            :label="option.storeName"
                                            :value="option.storeId"
                                    ></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item
                                label="优惠券类型"
                                :label-width="formLabelWidth"
                                prop="couponCode"
                        >
                            <div class="left">
                                <el-select
                                        v-model="addEditform.couponCode"
                                        placeholder="请选择优惠券类型"
                                        style="width:100%"
                                        @change="handleChange"
                                >
                                    <el-option
                                            v-for="option in options"
                                            :key="option.value"
                                            :label="option.label"
                                            :value="option.value"
                                    ></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item
                                :label="discountAmountLabel"
                                :label-width="formLabelWidth"
                                v-if="showInputA"
                                prop="discountAmount"
                        >
                            <div class="left">
                                <el-input
                                        v-model="addEditform.discountAmount"
                                        autocomplete="off"
                                        :placeholder="placeholderValue"
                                ></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item
                                label="优惠折扣(%)"
                                :label-width="formLabelWidth"
                                v-if="showInputB"
                                prop="discountPercentage"
                        >
                            <div class="left">
                                <el-input
                                        v-model="addEditform.discountPercentage"
                                        autocomplete="off"
                                        placeholder="请设置优惠折扣"
                                ></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item
                                label="最低订单金额(元)"
                                :label-width="formLabelWidth"
                                v-if="showInput"
                                prop="minimumOrderAmount"
                        >
                            <div class="left">
                                <el-input
                                        v-model="addEditform.minimumOrderAmount"
                                        autocomplete="off"
                                        placeholder="设置使用该优惠券的最低订单金额"
                                ></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item
                                label="优惠券使用时间"
                                :label-width="formLabelWidth"
                        >
                            <div class="left">

                                <el-col :span="11">
                                    <el-date-picker
                                            v-model="addEditform.startDate"
                                            type="datetime"
                                            placeholder="选择开始日期时间"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-col>
                                <el-col
                                        class="line"
                                        :span="2"
                                >-</el-col>
                                <el-col :span="11">
                                    <el-date-picker
                                            v-model="addEditform.endDate"
                                            type="datetime"
                                            placeholder="选择结束日期时间"
                                            value-format="yyyy-MM-dd HH:mm:ss"
                                    >
                                    </el-date-picker>
                                </el-col>
                            </div>
                        </el-form-item>
                        <el-form-item
                                label="优惠券数量"
                                :label-width="formLabelWidth"
                                prop="usageLimit"
                        >
                            <div class="left">
                                <el-input
                                        v-model="addEditform.usageLimit"
                                        autocomplete="off"
                                        placeholder="请设置优惠券的数量"
                                ></el-input>
                            </div>
                        </el-form-item>
                    </el-form>
                    <span slot="footer" class="dialog-footer">
    <el-button @click="cancelbutt">取 消</el-button>
    <el-button type="primary" @click="enterAdd" v-if="actor=='add'">确定添加</el-button>
    <el-button type="primary" @click="enterEdit" v-if="actor=='edit'">确定编辑</el-button>
  </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CouponCodes",
        data() {
            return {
                searchForm:{
                    storeName:''
                },
                tableData:undefined,
                currentPage:1,
                pageSize:5,
                total:0,
                rules: {
                    storeId: [{required: true, message: '店铺不能为空', trigger: 'change'}],
                    usageLimit: [{pattern: /^[0-9]+$/,required: true, message: '请正确输入优惠券数量', trigger: 'change'}],
                    minimumOrderAmount: [{pattern: /^[0-9]+$/,required: true, message: '请正确输入最小使用金额', trigger: 'change'}],
                    discountPercentage: [{required: true, message: '请输入折扣', trigger: 'change'}],
                    discountAmount: [{required: true, message: '请输入折扣', trigger: 'change'}],
                    couponCode:[{required: true, message: '请输入优惠券类型', trigger: 'change'}],
                    couponName:[{required: true, message: '优惠券名称不能为空', trigger: 'change'}],
                },
                //确认按钮类型
                dialogVisible:false,
                actor: '',
                addEditform:{
                    couponCode:'',
                    couponName:'',
                    discountAmount:'',
                    endDate:'',
                    minimumOrderAmount:'',
                    startDate:'',
                    storeId:'',
                    usageLimit:'',
                },
                //优惠券类型
                options: [
                    { value: "NEW-USER-001", label: "满减" },
                    { value: "RETURN-CASH-002", label: "返现" },
                    { value: "CATEGORY-001", label: "折扣" },
                ],
                formLabelWidth: "120px",
                //门店信息
                stores: [],
                discountAmountLabel: "满减金额(元)",
                placeholderValue: "", //不同的优惠券提示信息不同
                showInput: false, // 控制输入框的显示与隐藏
                showInputA: false, // 控制输入框的显示与隐藏
                showInputB: false, // 控制输入框的显示与隐藏
            }
        },

        created() {
            this.loadCouponCodes();
            this.loadStoreMsg();
        },

        methods:{
            //加载门店信息
            loadStoreMsg(){
                this.$http.get("store/api/selectStoreByUserId/").then(resp => {
                    this.stores = resp.data.data;
                })
            },
            loadCouponCodes() {
                this.$http.post("store/coupon/selectCouponCodes/"+this.currentPage+"/"+this.pageSize,  this.searchForm).then(resp => {
                    console.log(resp.data.data.records);
                    this.tableData = resp.data.data.records;
                    this.total = resp.data.data.total;
                })
            },
            //获取用户类型，把数字转为字符
            getisUsed(row) {
                if (row.isUsed == 0) {
                    return "未使用";
                } else {
                    return "已使用";
                }
            },
            //搜索店名
            searchStore() {
                this.currentPage=1;
                this.loadCouponCodes();
            },
            //删除数据
            deleteCouponCodes(num) {
                this.$confirm('是否删除该数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete("/store/coupon/delCouponCodes/"+num).then(resp => {
                        if (resp.data.code === 2000) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.searchForm.currentPage = 1;
                            this.loadCouponCodes();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //添加按钮
            addCouponCodes(){
                this.actor = "add";
                this.dialogVisible = true;
                this.title = "添加优惠券"; //让title的值改变
                this.showInputA = false; //让输入框重新隐藏
                this.showInputB = false;
                this.showInput = false;
            },
            //确认添加按钮
            enterAdd(){
                this.$refs.addEditform.validate((valid) => {
                    this.addEditform.couponType=this.optionsVale(this.addEditform);
                    console.log(valid);
                        if (valid) {
                            this.actor = ''
                            console.log(this.addEditform, "--------------");
                            this.$http.post("/store/coupon/insertCouponCodes", this.addEditform).then(resp => {
                                if (resp.data.code === 2000) {
                                    this.$message({
                                        type: 'success',
                                        message: '添加成功!'
                                    });
                                    this.loadCouponCodes();
                                }
                            })
                            this.addEditform = {}
                            this.dialogVisible = false;
                        }
                    }
                )
            },
            //对话框关闭方法
            handleClose() {
                this.dialogVisible = false
                this.addEditform = {}
                this.loadCouponCodes();
            },
            //取消按钮
            cancelbutt() {
                this.dialogVisible = false
                this.addEditform = {};
                this.loadCouponCodes();
            },
            //编辑按钮
            editCouponCodes(row) {
                this.actor = "edit"
                this.dialogVisible = true;
                this.addEditform.storeId = row.storeId
                this.addEditform = row;
                //判断优惠券的类型是什么
                if (this.addEditform.couponCode === "NEW-USER-001") {
                    //如果是满减
                    this.discountAmountLabel = "满减金额(元)";
                    this.placeholderValue = "请设置满足该优惠券使用条件后优惠的金额";
                    this.showInputA = true;
                    this.showInputB = false;
                    this.showInput = true;
                } else if (this.addEditform.couponCode === "RETURN-CASH-002") {
                    //如果是折现
                    this.discountAmountLabel = "返现金额(元)";
                    this.placeholderValue = "请设置满足该优惠券使用条件后返现的金额";
                    this.showInputA = true;
                    this.showInputB = false;
                    this.showInput = true;
                } else if (this.addEditform.couponCode === "CATEGORY-001") {
                    //如果是折扣
                    this.showInputB = true;
                    this.showInputA = false;
                    this.showInput = false;
                }
            },
            //确认编辑按钮
            enterEdit(){
                this.$refs.addEditform.validate((valid) => {
                    this.addEditform.couponType=this.optionsVale(this.addEditform);
                    console.log(valid);
                    if (valid) {
                        this.actor = "";
                        console.log(this.addEditform, "-------------")
                        this.$http.put("/store/coupon/updateCouponCodes", this.addEditform).then(resp => {
                            if (resp.data.code === 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功!'
                                });
                                this.loadCouponCodes();
                            }
                        })
                        this.addEditform = {}
                        this.dialogVisible = false;
                    }
                })
            },
            //获取用户类型，把数字转为字符
            getdiscountAmount(row) {
                if (row.discountAmount == null) {
                    return "无";
                } else {
                    return row.discountAmount;
                }
            },
            //获取用户类型，把数字转为字符
            getdiscountPercentage(row) {
                if (row.discountPercentage == null) {
                    return "无";
                } else {
                    return row.discountPercentage;
                }
            },
            //获取用户类型，把数字转为字符
            getminimumOrderAmount(row) {
                if (row.minimumOrderAmount == null) {
                    return "无";
                } else {
                    return row.minimumOrderAmount;
                }
            },
            //实现点击不同的优惠券类型，下面的输入框要求输入的内容不同
            handleChange() {
                // alert("执行了嘛");
                //判断优惠券的类型是什么
                if (this.addEditform.couponCode === "NEW-USER-001") {
                    //如果是满减
                    this.discountAmountLabel = "满减金额(元)";
                    this.placeholderValue = "请设置满足该优惠券使用条件后优惠的金额";
                    this.showInputA = true;
                    this.showInputB = false;
                    this.showInput = true;
                } else if (this.addEditform.couponCode === "RETURN-CASH-002") {
                    //如果是折现
                    this.discountAmountLabel = "返现金额(元)";
                    this.placeholderValue = "请设置满足该优惠券使用条件后返现的金额";
                    this.showInputA = true;
                    this.showInputB = false;
                    this.showInput = true;
                } else if (this.addEditform.couponCode === "CATEGORY-001") {
                    //如果是折扣
                    this.showInputB = true;
                    this.showInputA = false;
                    this.showInput = false;
                }
            },
            //修改页大小
            handleSizeChange(num) {
                this.pageSize = num;
                this.loadCouponCodes();
            },
            //修改当前页
            handleCurrentChange(num) {
                this.currentPage = num;
                this.loadCouponCodes();
            },
            //刷新按钮
            flushedCouponCodes(){
                this.searchForm.storeName='';
                this.loadCouponCodes();
            },
            optionsVale(row){
                for (let item of this.options) {
                    if (row.couponCode === item.value) {
                        return item.label;
                    }
                }
                return ""; // 如果没有找到对应的 storeId，则返回一个默认值
            }
        }
    }
</script>

<style scoped>

</style>