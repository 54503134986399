<template>
    <div id="app">
        <!--搜素，对应后端的模糊查询-->
        <el-row :gutter="20">
            <el-col :span="6">
                <el-input
                        v-model="searchForm.carNo"
                        placeholder="请输入车牌号"
                        size="big"
                ></el-input>
            </el-col>
            <el-col :span="18">
                <el-button
                        type="success"
                        icon="el-icon-search"
                        @click="search"
                        size="big"
                >搜索
                </el-button
                >
                <el-button
                        type="primary"
                        icon="el-icon-refresh"
                        @click="refresh"
                        size="big"
                >刷新
                </el-button
                >
            </el-col>
            <el-col :span="9"></el-col>
        </el-row>

        <!-- 换行 -->
        <el-row>
            <el-col :span="24"> &nbsp;</el-col>
        </el-row>
        <el-table :data="tableData" align="center">
            <el-table-column prop="carNo" fixed label="车牌号"  align="center">
            </el-table-column>
            <el-table-column prop="userName" label="所属用户" >
            </el-table-column>
            <el-table-column prop="phone" label="手机号"  align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注信息"  align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"  align="center">
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.current"
                :page-sizes="[1,2,5,10]"
                :page-size="searchForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "Car",
        data() {
            return {
                tableData: [],
                searchForm: {
                    //分页
                    current: 1,
                    pageSize: 5,
                    carNo:''
                },
                total: 0,
            }
        },
        created() {
            this.loadData();
        },
        methods: {
            //分页查询所有车辆信息
            loadData() {
                this.$http.post("vips/car/getAll",this.searchForm).then((result => {
                    if (result.data.code === 200) {
                        /*if(this.searchForm.carNo!=null||this.searchForm.carNo!=""){
                            this.searchForm.current=1
                        }*/
                        this.tableData = result.data.data.records;
                        this.total = result.data.data.total;
                    } else {
                        this.$message.error("查询发生错误");
                    }
                }))
            },
            search() {
                this.loadData();
            },
            //刷新方法
            refresh() {
                this.searchForm.carNo=null
                this.searchForm.current=1;
                this.loadData();
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.searchForm.pageSize = val;
                this.searchForm.current=1;
                this.loadData();
            },

            //页码发生变化（第几页）
            handleCurrentChange(val) {
                this.searchForm.current = val;
                this.loadData();
            },
        },
    };
</script>

<style scoped>

</style>