<template>
    <div id="app">
        <!--标头 搜索框 开始-->
        <div id="searchData">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="searchForm.storeName" placeholder="——请按照店名搜索——"
                              class="center-text"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="searchStore" icon="el-icon-search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addStore" icon="el-icon-circle-plus-outline">添加</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="flushedStore" icon="el-icon-refresh">刷新</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!--标头 搜索框 结束-->

        <!--表格 数据 开始-->
        <div id="myTable">
            <el-table
                    :data="tableData"
                    height="500"
                    border
                    style="width: 100%">
                <el-table-column width="200" prop="storeName" label="门店名称">
                </el-table-column>
                <el-table-column width="150" prop="openTime" label="开门时间">
                </el-table-column>
                <el-table-column width="150" prop="closeTime" label="关门时间">
                </el-table-column>

                <el-table-column
                        sortable
                        width="120"
                        prop="status"
                        label="门店状态"
                >
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.status=='0'" effect="dark">营业中</el-tag>
                        <el-tag v-if="scope.row.status=='1'" type="success" effect="dark">空闲中</el-tag>
                    </template>
                </el-table-column>

                <el-table-column label="门店图片" width="160" height="120">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 70px; height: 70px"
                                :src="scope.row.storeimage"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column width="350" prop="address" label="门店地址">
                </el-table-column>
                <el-table-column width="120" prop="phone" label="门店电话">
                </el-table-column>
                <el-table-column width="100" prop="country" label="国家">
                </el-table-column>
                <el-table-column width="100" prop="province" label="省份">
                </el-table-column>
                <el-table-column width="100" prop="city" label="城市"></el-table-column>
                <el-table-column sortable width="150" prop="createBy" label="创建人">
                </el-table-column>
                <el-table-column
                        sortable
                        width="180"
                        prop="createTime"
                        label="门店创建时间"
                >
                </el-table-column>
                <el-table-column sortable width="150" prop="updateBy" label="更新人">
                </el-table-column>
                <el-table-column
                        sortable
                        width="180"
                        prop="updateTime"
                        label="门店更新时间"
                >
                </el-table-column>
                <el-table-column
                        label="操作"
                        width="230"
                        fixed="right">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="editStore(scope.row)" icon="el-icon-edit-outline">编辑
                        </el-button>
                        <el-button type="danger" @click="deleteStore(scope.row.storeId)"
                                   icon="el-icon-remove-outline">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--表格 数据 结束-->
        <!--分页 信息开始-->
        <div id="pagination">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[1, 5, 10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
        <!--分页 信息结束-->
        <div id="dialog">
            <!--添加和编辑的对话框-->
            <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="40%"
                    :before-close="handleClose">
                <el-form ref="addEditform" :rules="rules" :model="addEditform" label-width="150px">
                    <el-form-item
                            label="门店名称"
                            prop="storeName"

                    >
                        <el-input v-model="addEditform.storeName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-col :span="2"></el-col>
                    <el-form-item
                            label="门店状态"
                            prop="status">
                        <!-- 这个是单选按钮样式 -->
                        <el-radio v-model="addEditform.status" label="0">营业中</el-radio>
                        <el-radio v-model="addEditform.status" label="1">休息中</el-radio>
                    </el-form-item>
                    <el-form-item
                            label="开门时间"
                            prop="openTime"
                    >
                        <!--                        <el-input v-model="addEditform.openTime" autocomplete="off"></el-input>-->
                        <el-time-select
                                v-model="addEditform.openTime"
                                :picker-options="{
                                          start: '08:30',
                                          step: '00:15',
                                          end: '18:30'
                                            }"
                                placeholder="选择时间">
                        </el-time-select>
                    </el-form-item>
                    <el-form-item
                            label="关门时间"
                            prop="closeTime"
                    >
                        <!--                        <el-input v-model="addEditform.closeTime"  autocomplete="off"></el-input>-->
                        <el-time-select
                                v-model="addEditform.closeTime"
                                :picker-options="{
                                          start: '08:30',
                                          step: '00:15',
                                          end: '18:30'
                                          }"
                                placeholder="选择时间">
                        </el-time-select>
                    </el-form-item>
                    <el-form-item
                            label="门店电话"
                            prop="phone"
                    >
                        <el-input v-model="addEditform.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="店铺图片">
                        <el-upload
                                class="avatar-uploader"
                                action="//192.168.28.124:7001/store/api/uploadfile"
                                :headers="head"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="addEditform.storeimage" :src="addEditform.storeimage" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <div class="mapButton" style="margin-bottom: 15px; ">
                        <span>点击选择门店位置&nbsp;&nbsp;&nbsp;</span>
                        <el-button
                                icon="el-icon-location"
                                style="color: #007acc"
                                circle
                                @click="openMap"
                        ></el-button>

                    </div>
                    <el-form-item
                            label="所在国家"
                            prop="country"
                    >
                        <el-input v-model="addEditform.country" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item
                            label="所在省份"
                            :readonly="true"
                            prop="province"
                    >
                        <el-input
                                v-model="addEditform.province"
                                :disabled="true"
                                autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                            label="所在城市"
                            prop="city"
                    >
                        <el-input
                                v-model="addEditform.city"
                                :disabled="true"
                                autocomplete="off"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                            label="门店地址"
                            prop="address"
                    >
                        <el-input v-model="addEditform.address" autocomplete="off"></el-input>
                    </el-form-item>

                </el-form>
                <span slot="footer" class="dialog-footer">
    <el-button @click="cancelbutt">取 消</el-button>
    <el-button type="primary" @click="enterAdd" v-if="actor=='add'">确定添加</el-button>
    <el-button type="primary" @click="enterEdit" v-if="actor=='edit'">确定编辑</el-button>
  </span>
            </el-dialog>
        </div>

        <div id="mapDialog">
            <el-dialog
                    title="提示"
                    :visible.sync="dialogMapVisible"
                    width="45%"
                    :before-close="handleCloseMap"
            >
                <div style="float: left">
                    <el-form ref="form" :model="searchMapForm" label-width="80px">
                        <el-form-item label="地址">

                            <input
                                    v-model="searchMapForm.name"
                                    placeholder="输入地址进行搜索"
                                    @input="handleInputChange"
                                    style="height: 35px; margin-right: 10px"
                            />
                            <ul v-if="suggestions.length > 0" class="smap">
                                <li v-for="(suggestion, index) in suggestions" :key="index"
                                    @click="selectSuggestion(suggestion)">
                                    {{ suggestion.name }}
                                </li>
                            </ul>

                            <el-button type="primary" @click="searchMap" icon="el-icon-refresh">刷新</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div id="container" style="height: 400px; width: 640px;"></div>
                <span slot="footer" class="dialog-footer">
    <el-button @click="resetting">重置</el-button>
    <el-button type="primary" @click="addMap">确 定</el-button>
  </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import AMapLoader from "@amap/amap-jsapi-loader";
    window._AMapSecurityConfig = {
        securityJsCode: '7971203447efcf714e19955cabf5d9be' //申请的安全密钥
    }
    export default {
       name: "store",
        data() {
            return {
                //条件
                searchForm: {
                    storeName: "",
                },
                tableData:undefined,
                //分页信息 页码 大小 总数
                currentPage:1,
                pageSize:5,
                total:0,
                //对话框默认值
                dialogVisible: false,
                rules: {
                    storeName: [{required: true, message: '门店名称不能为空', trigger: 'change'}],
                    status: [{required: false}],
                    openTime: [{required: true, message: '开门时间不能为空', trigger: 'change'}],
                    closeTime: [{required: true, message: '关门时间不能为空', trigger: 'change'}],
                    phone: [{pattern: /^1[3456789]\d{9}$/,required: true, message: '请输入正确的电话格式', trigger: 'change'}],
                    address: [{required: true, message: '门店地址不能为空', trigger: 'change'}],
                },
                addEditform: {
                    picture:'',
                },
                //文件上传路径
                uploadAction:'',
                actor: '',
                suggestions: [],
                autoComplete: null,
                //默认搜索地址
                searchMapForm: {
                    name: ''
                },
                province: "", //省份
                city: "", //城市
                addressDetails: "", //详细地址
                longitude: "", //经度
                latitude: "", //纬度
                point: "", //中心点坐标
                country: "",
                //地图对话框
                dialogMapVisible: false,
            }
        },

        created() {
            this.loadStore();
            this.head = {
                token: sessionStorage.getItem("token")
            }
        },

        methods:{
            //加载店铺
            loadStore() {
                this.$http.post("store/api/selectPageStore/"+this.currentPage+"/"+this.pageSize,this.searchForm).then(resp => {
                    this.tableData = resp.data.data.records;
                    this.total = resp.data.data.total;
                })
            },
            //取消按钮
            cancelbutt() {
                this.dialogVisible = false
                this.addEditform = {};
                this.loadStore();
            },
            //添加按钮的方法
            addStore() {
                this.addEditform.status='0';
                this.actor = "add";
                this.dialogVisible = true;
            },
            //确认添加事件
            enterAdd(){
                this.$refs.addEditform.validate((valid) => {
                        console.log(valid);
                        if (valid) {
                            this.$http.post("/store/api/insertStore", this.addEditform).then(resp => {
                                if (resp.data.code === 2000) {
                                    this.$message({
                                        type: 'success',
                                        message: '添加成功!'
                                    });
                                    this.loadStore();
                                }
                            })
                            this.actor = ''
                            this.addEditform = {}
                            this.dialogVisible = false;
                        }
                    }
                );
            },
            //修改按钮
            editStore(row) {
                this.actor = "edit"
                this.dialogVisible = true;
                this.addEditform = row;
            },
            //确认修改事件
            enterEdit(){
                this.$refs.addEditform.validate((valid) => {
                    console.log(valid);
                    if (valid) {
                        this.actor = "";
                        this.$http.put("/store/api/updateStoreById", this.addEditform).then(resp => {
                            if (resp.data.code === 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功!'
                                });
                                this.loadStore();
                            }
                        })
                        this.addEditform = {}
                        this.dialogVisible = false;
                    }
                });
            },
            //删除按钮事件
            deleteStore(storeid) {
                this.$confirm('是否删除该数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete("/store/api/delStore/" + storeid).then(resp => {
                        if (resp.data.code === 2000) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.searchForm.currentPage = 1;
                            this.loadStore();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            //
            openMap(){},
            //对话框关闭方法
            handleClose() {
                this.dialogVisible = false
                this.addEditform = {}
                this.loadStore();
            },
            //修改页大小
            handleSizeChange(num) {
                this.pageSize = num;
                this.loadStore();
            },
            //修改当前页
            handleCurrentChange(num) {
                this.currentPage = num;
                this.loadStore();
            },
            //搜索店名
            searchStore() {
                this.currentPage= 1;
                this.loadStore();
            },
            //刷新按钮
            flushedStore() {
                this.searchForm.storeName='';
                this.loadStore();
            },
            //图片上传的方法
            handleAvatarSuccess(res, file) {
                this.addEditform.storeimage = res.data;
            },
            beforeAvatarUpload(file) {
                const isImage = file.type.startsWith('image/');
                const isJPGorPNG = isImage && (file.type === 'image/jpeg' || file.type === 'image/png');
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPGorPNG) {
                    this.$message.error('上传头像图片只能是 JPG或者是PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPGorPNG && isLt2M;
            },

            //点开地图的小图标
            openMap() {
                this.dialogMapVisible = true
                this.initMap();
            },
            //搜索地址
            searchMap() {
                this.initMap();
            },
            //点击
            selectSuggestion(suggestion) {
                this.searchMapForm.name = suggestion.name;
                this.suggestions = [];
            },
            //加载地图
            initMap() {
                AMapLoader.load({
                    "key": "f2e81a7c6a4003d77fecd9ea57c78da8",   // 申请好的Web端开发者Key，首次调用 load 时必填
                    "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                    "plugins": ['Autocomplete', 'PlaceSearch']  //插件列表
                }).then((AMap) => {
                    let map = new AMap.Map('container', {
                        center: [113.264385, 23.12911],
                        zoom: 13
                    });
                    let trafficLayer = new AMap.TileLayer.Traffic({
                        zIndex: 2
                    });

                    let _this = this;
                    var geocoder;
                    window.AMap.plugin('AMap.PlaceSearch', function () {
                        var autoOptions = {
                            city: '全国',
                            // map: _this.map, // 展现结果的地图实例
                            pageSize: 1, // 单页显示结果条数
                            pageIndex: 1, // 页码
                            autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                        };
                        var placeSearch = new window.AMap.PlaceSearch(autoOptions);
                        placeSearch.search(_this.searchMapForm.name, function (status, result) {
                            // console.log(result)
                            // 搜索成功时，result即是对应的匹配数据
                            if (status == 'complete') {
                                if (result.poiList.pois.length == 0) {
                                    // ElMessage.error('没有查询到对应的地址');
                                    console.log("没有查询到对应的地址")
                                } else {

                                    let lng = result.poiList.pois[0].location.lng;
                                    let lat = result.poiList.pois[0].location.lat
                                    geocoder.getAddress([lng, lat], (status, result) => {
                                        if (status === 'complete' && result.info === 'OK') {
                                            // 输出国家信息
                                            const country = "中国"
                                            console.log('国家:', country);
                                            that.latitude = lat;
                                            that.longitude = lng
                                            that.addressDetails = result.regeocode.formattedAddress//获取详细地址
                                            that.country = country;
                                            // 输出省份信息
                                            const province = result.regeocode.addressComponent.province;
                                            console.log('省份:', province);
                                            that.province = province;
                                            // 输出城市信息
                                            const city = result.regeocode.addressComponent.city;
                                            console.log('城市:', city);
                                            that.city = city;
                                        } else {
                                            console.error('获取地址失败');
                                        }
                                        let infoWindow = new AMap.InfoWindow({
                                            content: that.addressDetails, // 替换为实际的地址信息
                                            position: [lng, lat] // 替换为实际的经纬度坐标
                                        });
                                        // 在地图上打开信息窗体
                                        infoWindow.open(map, [lng, lat]);
                                    });
                                }
                            } else if (status == 'no_data') {
                                console.log("没有查询到对应的地址")
                                // ElMessage.error('没有查询到对应的地址');
                            }
                        });
                    });

                    // 周边搜索函数


                    AMap.plugin(("AMap.Geocoder"), function () {
                        geocoder = new AMap.Geocoder({
                            city: '全国', // 如果你想获得更精准的城市信息，可以指定特定城市，例如 '北京市'
                            // radius: 1000,// 查询半径，单位：米
                            extensions: "all"
                        });
                    });
                    let that = this
                    map.on('click', getMap);

                    function getMap(e) {
                        // 获取点击位置的经纬度
                        const lnglat = e.lnglat;
                        const lat = e.lnglat.lat
                        const lng = e.lnglat.lng
                        // 在控制台输出经纬度信息
                        console.log('点击位置的经纬度：', lnglat);
                        // 逆地理编码
                        geocoder.getAddress([lng, lat], (status, result) => {
                                if (status === 'complete' && result.info === 'OK') {
                                    // 输出国家信息
                                    const country = "中国"
                                    console.log('国家:', country);
                                    that.latitude = lat;
                                    that.longitude = lng
                                    that.addressDetails = result.regeocode.formattedAddress//获取详细地址
                                    that.country = country;
                                    // 输出省份信息
                                    const province = result.regeocode.addressComponent.province;
                                    console.log('省份:', province);
                                    that.province = province;
                                    // 输出城市信息
                                    const city = result.regeocode.addressComponent.city;
                                    console.log('城市:', city);
                                    that.city = city;
                                } else {
                                    console.error('获取地址失败');
                                }
                                let infoWindow = new AMap.InfoWindow({
                                    content: that.addressDetails, // 替换为实际的地址信息
                                    position: [lng, lat] // 替换为实际的经纬度坐标
                                });
                                // 在地图上打开信息窗体
                                infoWindow.open(map, [lng, lat]);
                            }
                        );
                    }

                    map.add(trafficLayer);//添加图层到地图
                    map.add(geocoder)
                }).catch(e => {
                    console.log(e);
                })
            },
            loadAutoComplete() {
                AMapLoader.load({
                    key: "f2e81a7c6a4003d77fecd9ea57c78da8", // 替换为你的高德地图 API Key
                    version: "2.0",
                    plugins: ["AMap.AutoComplete"],
                }).then((AMap) => {
                    this.initAutoComplete();
                });
            },
            initAutoComplete() {
                this.autoComplete = new AMap.AutoComplete({
                    input: "inputBox", // 输入框的ID，这里替换为你的输入框的ID
                });
                this.autoComplete.on("select", (e) => {
// 处理选中的地址
                    console.log("选中的地址：", e.poi.name);
                });

                this.autoComplete.on("listElementClick", (e) => {
// 处理点击下拉列表的项
                    console.log("点击的列表项：", e.poi.name);
                });
            },
            handleInputChange() {
                if (this.searchMapForm.name) {
// 根据输入的关键词获取地址列表
                    this.autoComplete.search(this.searchMapForm.name, (status, result) => {
                        if (status === "complete") {
                            console.log(result)
                            this.suggestions = result.tips;
                        }
                    });
                } else {
                    this.suggestions = [];
                    console.log(this.suggestions)
                }
            },
            //添加地图
            addMap() {
                this.addEditform.country = this.country;
                this.addEditform.address = this.addressDetails; //详细地址
                this.addEditform.latitude = this.latitude; //纬度
                this.addEditform.longitude = this.longitude; //经度
                this.addEditform.city = this.city; //城市名
                this.addEditform.province = this.province; //省份
                this.searchMapForm.name = '';
                this.dialogMapVisible = false; //弹框关闭

            },
            //点击地图里面的重置按钮 触发这个函数
            resetting() {
                // alert(JSON.stringify(response));
                this.addEditform.address = "";
                this.addEditform.latitude = "";
                this.addEditform.longitude = "";
                this.addEditform.city = "";
                this.addEditform.province = "";
                this.searchMapForm.name = '';
                this.dialogMapVisible = false;

            },
            //地图对话框关闭
            handleCloseMap() {
                this.searchMapForm.name = '';
                this.dialogMapVisible = false;
            }
        }
    }
</script>

<style scoped>
    #searchData {
        float: left;
    }
    /*上传图片的样式*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;

        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        align-items: center;
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 70px;
        padding-top: 50px;
    }

    .avatar {
        width: 120px;
        height: 120px;

        display: block;
    }

    .center-text {
        /*text-align: center;*/
    }
</style>