<template>
    <div id="app">
        <div id="searchData">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="searchForm.storeName" placeholder="——请输入店名——"
                              class="center-text"></el-input>
                </el-form-item>
                <!--<el-form-item>
                    <el-input v-model="searchForm.userName" placeholder="——请输入用户名——"
                              class="center-text"></el-input>
                </el-form-item>-->
                <el-form-item>
                    <el-button type="success" @click="searchStoreAndUser" icon="el-icon-search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addStation" icon="el-icon-circle-plus-outline">添加</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="flushedEvaluate" icon="el-icon-refresh">刷新</el-button>
                </el-form-item>
            </el-form>

        </div>
        <div id="myTable">
            <el-table
                    :data="tableData"
                    height="500"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="store.storeName"
                        label="门店"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="frontUser.userName"
                        label="用户"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="content"
                        label="评价内容"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="picture"
                        label="评级图片"
                        width="250">
                    <template slot-scope="scope">
                        <el-image :src="scope.row.picture" style="width: 100px; height: 56px"></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="star"
                        label="星级">
                </el-table-column>
                <el-table-column
                        prop="evaluateTime"
                        label="评价时间">
                </el-table-column>

                <el-table-column
                        fixed="right"
                        label="操作"
                        width="270">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="editEvaluate(scope.row)" icon="el-icon-edit-outline">编辑
                        </el-button>
                        <el-button type="danger" @click="deleteEvaluateId(scope.row.evaluateId)"
                                   icon="el-icon-remove-outline">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div id="pagination">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[1, 5, 10, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>

        <div id="dialog">
            <!--添加和编辑的对话框-->
            <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :before-close="handleClose">
                <el-form ref="addEditform" :model="addEditform" :rules="rules" label-width="80px">
                    <el-form-item label="门店" prop="storeId">
                        <el-select placeholder="请选择门店" v-model="addEditform.storeId" style="width: 100%;">
                            <el-option
                                    v-for="item in stores"
                                    :label="item.storeName"
                                    :value="item.storeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="用户" prop="userId">
                        <el-select placeholder="请选择用户" v-model="addEditform.userId" style="width: 100%;">
                            <el-option
                                    v-for="item in userMsg"
                                    :label="item.userName"
                                    :value="item.userId+''">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评论图片">
                        <el-upload
                                class="avatar-uploader"
                                action="//192.168.28.124:7001/store/api/uploadfile"
                                :headers="head"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="addEditform.picture" :src="addEditform.picture" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-form-item label="评论内容" prop="content">
                        <el-input v-model="addEditform.content"></el-input>
                    </el-form-item>
                    <el-form-item label="星级" prop="star">
                        <!--                      <el-input v-model="addEditform.star"></el-input>-->
                        <el-select v-model="addEditform.star" placeholder="--请选择星级--" style="width: 40%; float: left" >
                            <el-option label="★" value="1"></el-option>
                            <el-option label="★★" value="2"></el-option>
                            <el-option label="★★★" value="3"></el-option>
                            <el-option label="★★★★" value="4"></el-option>
                            <el-option label="★★★★★" value="5"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
    <el-button @click="cancelbutt">取 消</el-button>
    <el-button type="primary" @click="enterAdd" v-if="actor=='add'">确定添加</el-button>
    <el-button type="primary" @click="enterEdit" v-if="actor=='edit'">确定编辑</el-button>
  </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StoreEvaluate",

        data(){
            return{
                uploadAction:'',
                //确认按钮类型
                actor: '',
                searchForm:{
                    storeName:undefined,
                },
                dialogVisible: false,
                currentPage:1,
                pageSize:5,
                tableData:undefined,
                total:0,
                addEditform: {
                    idle: '',
                    picture: '',
                    stationName: '',
                    storeId: '',
                },
                //门店信息
                stores: [],
                rules: {
                    storeId: [{required: true, message: '门店名称不能为空', trigger: 'change'}],
                    stationName: [{required: true, message: '库不能为空', trigger: 'change'}],
                },
                userMsg:[],
            }
        },
        created() {
            this.loadEvaluate();
            this.loadStoreMsg();
            //this.loadUserMsg();
            this.head = {
                token: sessionStorage.getItem("token")
            }
        },
        methods:{
            //加载用户信息
            /*loadUserMsg(){
                this.$http.post("#").then(resp=>{
                    this.userMsg=resp.data.data;
                })
            },*/
            loadEvaluate(){
                this.$http.post("/store/evaluate/selectEvaluate/"+this.currentPage+"/"+this.pageSize, this.searchForm).then(resp => {
                    console.log(resp.data.data.records);
                    this.tableData = resp.data.data.records;
                    this.tableData.forEach(item=>{
                        if(item.frontUser.userName==null){
                            item.frontUser.userName="匿名用户"
                        }
                    });
                    this.total = resp.data.data.total;
                })
            },
            //加载门店信息
            loadStoreMsg(){
                this.$http.get("store/api/selectStoreByUserId/").then(resp => {
                    this.stores = resp.data.data;
                })
            },
            //修改页大小
            handleSizeChange(num) {
                this.pageSize = num;
                this.loadEvaluate();
            },
            //修改当前页
            handleCurrentChange(num) {
                this.currentPage = num;
                this.loadEvaluate();
            },
            //修改评论
            editEvaluate(row) {
                this.actor = "edit"
                this.dialogVisible = true;
                this.addEditform = row;
            },
            //确认编辑方法
            enterEdit() {
                this.$refs.addEditform.validate((valid) => {
                    console.log(valid);
                    if (valid) {
                        this.actor = "";
                        this.$http.put("/store/evaluate/updateEvaluate", this.addEditform).then(resp => {
                            if (resp.data.code === 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功!'
                                });
                                this.loadEvaluate();
                            }
                        })
                        this.addEditform = {}
                        this.dialogVisible = false;
                    }
                })
            },
            //搜索店名和用户名
            searchStoreAndUser() {
                this.currentPage= 1;
                this.loadEvaluate();
            },
            //取消按钮
            cancelbutt() {
                this.dialogVisible = false
                this.addEditform = {};
                this.loadEvaluate();
            },
            //添加按钮的方法
            addStation() {
                this.actor = "add";
                this.dialogVisible = true;
            },
            //确认添加方法
            enterAdd() {
                this.$refs.addEditform.validate((valid) => {
                    console.log(valid);
                    if (valid) {
                        this.actor = ''
                        console.log(this.addEditform, "--------------");
                        this.$http.post("/store/evaluate/insertEvaluate", this.addEditform).then(resp => {
                            if (resp.data.code === 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '添加成功!'
                                });
                                this.loadEvaluate();
                            }
                        })
                        this.addEditform = {}
                        this.dialogVisible = false;
                    }
                })
            },
            //刷新按钮
            flushedEvaluate(){
                this.searchForm.storeName='';
                this.loadEvaluate();
            },
            //删除评论
            deleteEvaluateId(row){
                this.$confirm('是否删除该数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete("/store/evaluate/delEvaluate/" + row).then(resp => {
                        if (resp.data.code === 2000) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.searchForm.currentPage = 1;
                            this.loadEvaluate();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            //对话框关闭方法
            handleClose() {
                this.dialogVisible = false
                this.addEditform = {}
                this.loadEvaluate();
            },
            //图片上传的方法
            handleAvatarSuccess(res, file) {
                console.log(res, "-------------------")
                this.addEditform.picture = res.data
            },
            beforeAvatarUpload(file) {
                const isImage = file.type.startsWith('image/');
                const isJPGorPNG = isImage && (file.type === 'image/jpeg' || file.type === 'image/png');
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPGorPNG) {
                    this.$message.error('上传头像图片只能是 JPG或者是PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPGorPNG && isLt2M;
            },
        }
    }
</script>

<style scoped>
    #searchData {
        float: left;
    }
    /*上传图片的样式*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;

        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        align-items: center;
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 70px;
        padding-top: 50px;
    }

    .avatar {
        width: 120px;
        height: 120px;

        display: block;
    }

    .center-text {
        /*text-align: center;*/
    }
</style>