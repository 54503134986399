<template>
    <div>
        <el-container>
            <el-container style="height: 100%">
                <!-- 左边布局 -->
                <el-aside width="250px" style="height: calc(100vh - 64px); overflow-y: auto;">
                    <!--左侧树形菜单-->
                    <el-tree
                            :data="data"
                            :props="defaultProps"
                            @node-click="handleNodeClick"
                            :highlight-current="true"
                            default-expand-all
                            style="padding: 20px 20px 0"
                    ></el-tree>
                </el-aside>
                <!-- 主要内容布局 -->
                <el-main>
                    <el-form :model="form">
                        <el-form-item label="菜单名称" :label-width="formLabelWidth">
                            <el-input v-model="form.menuName" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="菜单类型" :label-width="formLabelWidth">
                            <el-radio-group v-model="form.menuType">
                                <el-radio label="M">目录</el-radio>
                                <el-radio label="C">菜单</el-radio>
                                <el-radio label="F">按钮</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="父节点" :label-width="formLabelWidth" v-if="form.menuType!='M'">
                            <!--设置一个有隐藏的父ID，用于传参-->
                            <input type="hidden" name="form.pare                                                                                                                                     ntId"/>
                            <!-- @focus是元素获取焦点时所触发的事件 -->
                            <el-input
                                    v-model="parentName"
                                    @focus="dialogFormVisible = true"

                            ></el-input>
                        </el-form-item>
                        <el-form-item label="页面uri" :label-width="formLabelWidth">
                            <el-input v-model="form.url" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="权限字符" :label-width="formLabelWidth">
                            <el-input v-model="form.perms" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item label="图标" :label-width="formLabelWidth">
                            <el-input v-model="form.icon" autocomplete="off"></el-input>
                        </el-form-item>
                        <el-form-item :label-width="formLabelWidth">
                            <el-button type="success" size="small" @click="refresh">刷新</el-button>
                            <el-button type="primary" size="small" @click="addTree"  v-if="!(isShow)">添加</el-button>
                            <el-button type="primary" size="small" @click="updateTree" v-if="isShow">修改</el-button>
                            <el-button
                                    type="danger"
                                    size="small"
                                    v-if="isShow"
                                    @click="goDelete">删除
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-main>
            </el-container>
            <!-- 添加菜单时，选择父节点时，弹出的树形菜单 -->
            <el-dialog title="权限树" :visible.sync="dialogFormVisible">
                <el-tree
                        :data="data"
                        :props="defaultProps"
                        show-checkbox
                        check-strictly="check-strictly"
                        default-expand-all
                        ref="parentMenuTree"
                >
                </el-tree>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="checkSelectNode">确 定</el-button>
                </div>
            </el-dialog>
        </el-container>
    </div>
</template>
<script>
    export default {
        name: "Menu",
        data() {
            return {
                data: [], //tree数据
                defaultProps: {
                    //传参属性
                    children: "children",
                    label: "menuName",

                },
                form: {
                    menuType: ""
                }, //添加权限表单
                formLabelWidth: "100px",
                isShow: false, //默认不显示删除按钮
                dialogFormVisible: false, //弹出层，默认不显示
                parentName: "" // 用于保存父节点名称的字段
            }
        },
        created() {
            this.init();
        },
        methods: {
            //点击左侧树形数据的时候触发函数
            handleNodeClick(data) {
                this.form = data;
                this.isShow = true;
                if (this.form.parentId!=0){
                this.$http.get("/system/menu/getParentName/"+this.form.parentId).then(result=>{
                    if (result.data.code === 200) {
                        this.$message.success(result.data.msg);
                        this.parentName=result.data.data.menuName;
                    } else {
                        this.$message.error(result.data.msg);
                    }


                })
                }else{
                    this.parentName="";
                }

            },
            //更新
            updateTree() {
                if (this.form.menuName === undefined || this.form.menuName === "") {
                    this.$message.error("未输入菜单名称");
                    return;
                }
                if (this.form.menuId === this.form.parentId) {
                    this.$message.error("父节点不能为自己");
                    return;
                }

                this.$http.put("/system/menu/updateMenu", this.form).then((res => {
                    if (res.data.code === 200) {
                        this.$message.success(res.data.msg);
                        this.dialogFormVisible = false;
                        this.refresh();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }))
            },
            //删除
            goDelete() {
                this.$confirm('此操作将删除该菜单, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete("/system/menu/deleteMenu/"+this.form.menuId).then((res => {
                        if (res.data.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.data.msg
                            });
                            //刷新
                            this.refresh();
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.data.msg
                            });
                        }
                    }))
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //刷新
            refresh() {
                location.reload();
            },
            //初始化
            init() {
                this.$http.get("/system/menu/selectTreeMenu").then(res => {
                    this.data = res.data.data;
                })
            },
            //添加
            addTree() {
                if (this.form.menuName === undefined || this.form.menuName === "") {
                    this.$message.error("未输入菜单名称");
                    return;
                }
                if (this.form.parentId === undefined || this.form.parentId === "") {
                    if (this.form.menuType!='M') {
                        this.$message.error("未选择父节点");
                        return;
                    }

                }



                if (this.form.menuType === undefined || this.form.menuType === "") {
                    this.$message.error("未选择菜单类型");
                    return;
                }

                this.$http.post("/system/menu/addMenu",this.form).then((res => {
                    if (res.data.code === 200) {
                        this.$message.success(res.data.msg);
                        this.dialogFormVisible = false;
                        this.refresh();
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }))
            },
            //检查选择的父节点
            checkSelectNode: function () {
                let nodes = this.$refs.parentMenuTree.getCheckedNodes();
                if (nodes.length > 1) {
                    this.$message.error("只能选择一个节点");
                    return;
                }
                this.parentName = nodes[0].menuName;
                this.form.parentId = nodes[0].menuId;
                //关闭弹出框
                this.dialogFormVisible = false;

            },
        }
    }
</script>
<style scoped>

</style>