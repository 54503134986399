<template>
    <div>
        <!--搜索-->
        <el-row :gutter="20">
            <el-col :span="6">
                <el-input
                        v-model="searchForm.userName"
                        placeholder="请输入用户名"
                        size="big"
                ></el-input>
            </el-col>
            <el-col :span="18">
                <el-button
                        type="success"
                        size="big"
                        icon="el-icon-search"
                        @click="search"
                >搜索
                </el-button
                >
                <el-button
                        type="primary"
                        size="big"
                        icon="el-icon-refresh"
                        @click="refresh"
                >刷新
                </el-button
                >
            </el-col>
            <el-col :span="2"></el-col>
        </el-row>
        <el-row>
            <el-col :span="24"> &nbsp;</el-col>
        </el-row>

        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="integralLogId" label="积分ID" width="180"  align="center">
            </el-table-column>

            <el-table-column prop="userName" label="用户名" width="180" align="center">
            </el-table-column>

            <el-table-column prop="credits" label="积分数值" align="center">
                <template slot-scope="scope">
                <span v-if="scope.row.expendScore && !scope.row.arrivalScore">
                +{{ scope.row.expendScore }}
                </span>
                <span v-else-if="!scope.row.expendScore && scope.row.arrivalScore">
                    -{{ scope.row.arrivalScore }}
                </span>
            </template>
            </el-table-column>
            <el-table-column prop="rechargeTime" label="积分记录创建时间" align="center"> </el-table-column>
            <el-table-column prop="storeName" label="积分来源" align="center"> </el-table-column>
        </el-table>
        <!--分页功能-->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.currentPage"
                :page-sizes="[1,2,5,10]"
                :page-size="searchForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "PointsManagement",
        data() {
            return {
                tableData: [],
                searchForm: {
                    //分页
                    //当前页
                    currentPage:1,
                    //从第几条开始
                    current: 0,
                    pageSize: 5,
                    userName:"",
                },
                total: 0,
            }


        },
        methods:{
            //分页查询所有用户信息
            loadData() {
                this.$http.post("vips/integral/getAll", this.searchForm).then((response => {
                    if (response.data.code === 200) {
                        // if(this.searchForm.userName!=null||this.searchForm.userName!=""){
                        //     this.searchForm.currentPage=1
                        // }
                        this.tableData = response.data.data.data;
                        this.total = response.data.data.total;
                    } else {
                        this.$message.error("查询发生错误");
                    }
                }))
            },

            search() {
                this.loadData();
            },
            //刷新方法
            refresh() {
                this.searchForm.userName=null;
                this.searchForm.currentPage= 1;
                this.searchForm.current = 0;
                this.loadData();
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.searchForm.pageSize = val;
                this.searchForm.currentPage=1;
                this.searchForm.current= 0;
                this.loadData();
            },

            //页码发生变化（第几页）
            handleCurrentChange(val) {
                this.searchForm.currentPage = val;
                this.searchForm.current = (this.searchForm.currentPage-1)*this.searchForm.pageSize;
                this.loadData();
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>