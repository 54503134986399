<template>
    <div>
        <!--搜索-->
        <el-row :gutter="20">
            <el-col :span="6">
                <el-input
                        v-model="searchForm.addressName"
                        placeholder="请输入收件人名称"
                        size="big"
                ></el-input>
            </el-col>
            <el-col :span="18">
                <el-button
                        type="success"
                        size="big"
                        icon="el-icon-search"
                        @click="search"
                >搜索
                </el-button
                >
                <el-button
                        type="primary"
                        size="big"
                        icon="el-icon-refresh"
                        @click="refresh"
                >刷新
                </el-button
                >
            </el-col>
            <el-col :span="2"></el-col>
        </el-row>
        <el-row>
            <el-col :span="24"> &nbsp;</el-col>
        </el-row>

        <el-table :data="tableData" style="width: 100%" align="center">
            <!--表示一列 prop与下面tableData中的属性名对应 label相当于表头 width宽度-->
            <el-table-column prop="userName" fixed label="客户" width="150"  align="center">
            </el-table-column>
            <el-table-column
                    prop="phone"
                    label="客户注册手机号"
                    width="150"
                    align="center"
            >
            </el-table-column>
            <el-table-column prop="addressName" label="收件人名称" width="150" align="center">
            </el-table-column>
            <el-table-column prop="phone" label="收件人手机号码" width="150" align="center">
            </el-table-column>
            <el-table-column prop="province" label="省份" width="150" align="center">
            </el-table-column>
            <el-table-column prop="city" label="城市" width="150"  align="center"> </el-table-column>
            <el-table-column prop="district" label="区县" width="150" align="center">
            </el-table-column>
            <el-table-column prop="detail" label="详细地址" width="150" align="center">
            </el-table-column>
            <el-table-column label="操作" width="150" fixed="right" align="center">
                <!--相当于父子组件中的props-->
                <template slot-scope="scope">
                    <el-button size="small" type="primary" @click="handleEdit(scope.$index, scope.row)"
                    >编辑</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--分页功能-->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.current"
                :page-sizes="[1,2,5,10]"
                :page-size="searchForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <!-- 更新/添加的from表单 -->
        <el-dialog :title="title" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item label="客户" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.userName"
                            autocomplete="off"
                            disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="客户注册手机号" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.userPhone"
                            autocomplete="off"
                            disabled
                    ></el-input>
                </el-form-item>
                <el-form-item label="收件人名称" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.addressName"
                            autocomplete="off"
                            placeholder="请输入收件人名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.phone"
                            autocomplete="off"
                            placeholder="请输入手机号"
                    ></el-input>
                </el-form-item>
                <el-form-item label="省份" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.province"
                            autocomplete="off"
                            placeholder="省份"
                    ></el-input>
                </el-form-item>
                <el-form-item label="城市" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.city"
                            autocomplete="off"
                            placeholder="城市"
                    ></el-input>
                </el-form-item>
                <el-form-item label="区县" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.district"
                            autocomplete="off"
                            placeholder="区县"
                    ></el-input>
                </el-form-item>
                <el-form-item label="详细地址" :label-width="formLabelWidth">
                    <el-input
                            v-model="form.detail"
                            autocomplete="off"
                            placeholder="详细地址"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addOrUpdate">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "AddressManagement",
        data() {
            return {
                tableData: [],
                searchForm: {
                    //分页
                    current: 1,
                    pageSize: 5,
                    addressName:"",
                },
                total: 0,
                //是否显示添加或更新的form对话框，false:不显示
                dialogFormVisible: false,

                title: "地址更改",
                //from对象，更新或者添加输入框中的值
                form: {
                    frontUser: { userName: "", phone: "" },
                    frontUserName: "",
                    frontUserPhone: "",
                    userName: "",
                    addressName: "",
                    phone: "",
                    province: "",
                    city: "",
                    district: "",
                    detail: "",
                    //客户的手机号
                    userPhone:"",
                },
                //名称的宽度
                formLabelWidth: "120px",
                //客户的手机号
            }
        },
        methods:{
            //用户编辑对话框显示
            handleEdit(index, row) {
                console.log(index, row);
                //让对话框显示
                this.dialogFormVisible = true;
                this.title = "地址更新";
                this.form = row;
                this.phone1=row.phone;
            },

            addOrUpdate() {
                this.$http.put("vips/address/updateAddress",this.form).then((response=>{
                    if (response.data.code === 200) {
                        this.$message({
                            message: "操作成功！",
                            type: "success",
                        });
                    } else {
                        this.$message.error("更新地址操作时发生错误");
                    }
                    this.loadData();
                    this.dialogFormVisible = false;
                }))

            },

            //分页查询所有地址信息
            loadData() {
                this.$http.post("vips/address/getAll", this.searchForm).then((response => {
                    if (response.data.code === 200) {
                        // if(this.searchForm.addressName!=null||this.searchForm.addressName!=""){
                        //     this.searchForm.current=1
                        // }
                        this.tableData = response.data.data.records;
                        this.total = response.data.data.total;
                    } else {
                        this.$message.error("查询发生错误");
                    }
                }))
            },
            search() {
                this.loadData();
            },
            //刷新方法
            refresh() {
                this.searchForm.carNo=null
                this.
                this.loadData();
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.searchForm.pageSize = val;
                this.searchForm.current=1;
                this.loadData();
            },

            //页码发生变化（第几页）
            handleCurrentChange(val) {
                this.searchForm.current = val;
                this.loadData();
            },
        },

        created() {
            this.loadData();
        }


    }
</script>

<style scoped>

</style>