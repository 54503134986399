<template>
    <div id="app">
        <div id="searchData">
            <el-form :inline="true" :model="searchForm" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="searchForm.storeName" placeholder="——请按照店名搜索——"
                              class="center-text"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="searchStore" icon="el-icon-search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addLabels" icon="el-icon-circle-plus-outline">添加</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="flushedLabels" icon="el-icon-refresh">刷新</el-button>
                </el-form-item>
            </el-form>

        </div>
        <div id="myTable">
            <el-table
                    :data="tableData"
                    height="389"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="store.storeName"
                        label="门店"
                        width="180">
                </el-table-column>

                <el-table-column
                        prop="labelContent"
                        label="门店标签">
                </el-table-column>
                <el-table-column
                        label="操作"
                        width="270">
                    <template slot-scope="scope">
                        <el-button type="primary"  @click="editLabel(scope.row)"  icon="el-icon-edit-outline">编辑
                        </el-button>
                        <el-button type="danger" @click="deleteLabel(scope.row.labelId)"
                                   icon="el-icon-remove-outline">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div id="pagination">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="searchForm.currentPage"
                    :page-sizes="[1, 5, 10, 20]"
                    :page-size="searchForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>
        </div>
        <div id="dialog">
            <!--添加和编辑的对话框-->
            <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="30%"
                    :before-close="handleClose">
                <el-form ref="addEditform" :model="addEditform" :rules="rules" label-width="80px">
                    <el-form-item label="门店" prop="storeId">
                        <el-select placeholder="请选择门店" v-model="addEditform.storeId" style="width: 100%;">
                            <el-option
                                    v-for="item in stores"
                                    :label="item.storeName"
                                    :value="item.storeId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="标签" prop="labelContent">
                        <el-input v-model="addEditform.labelContent"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
    <el-button @click="cancelbutt">取 消</el-button>
    <el-button type="primary" @click="enterAdd" v-if="actor=='add'">确定添加</el-button>
    <el-button type="primary" @click="enterEdit" v-if="actor=='edit'">确定编辑</el-button>
  </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "StoreLabels",
        data(){
            return{
                searchForm:{
                    storeName:undefined,
                },
                //确认按钮类型
                actor: '',
                currentPage:1,
                pageSize:5,
                tableData:undefined,
                total:0,
                rules: {
                    storeId: [{required: true, message: '店铺不能为空', trigger: 'change'}],
                    labelContent: [{required: true, message: '标签不能为空', trigger: 'change'}],
                },
                //增加和修改框的值
                addEditform: {
                    storeId: '',
                    labelContent: '',
                },
                dialogVisible: false,
                //门店信息
                stores: [],
            }
        },
        created() {
            this.loadLabels();
            this.loadStoreMsg();
        },
        methods:{
            //加载门店信息
            loadStoreMsg(){
                this.$http.get("store/api/selectStoreByUserId/").then(resp => {
                    this.stores = resp.data.data;
                })
            },
            //加载Labels
            loadLabels() {
                this.$http.post("store/labels/selectLabels/"+this.currentPage+"/"+this.pageSize, this.searchForm).then(resp => {
                    console.log(resp);
                    this.tableData = resp.data.data.records;
                    this.total = resp.data.data.total;
                })
            },
            //对话框关闭方法
            handleClose() {
                this.dialogVisible = false
                this.addEditform = {}
                this.loadLabels();
            },
            //取消按钮
            cancelbutt() {
                this.dialogVisible = false
                this.addEditform = {};
                this.loadLabels();
            },
            //编辑按钮
            editLabel(row) {
                this.actor = "edit"
                this.dialogVisible = true;
                this.addEditform.storeId = row.storeId
                this.addEditform = row;
            },
            //添加按钮的方法
            addLabels() {
                this.actor = "add";
                this.dialogVisible = true;
            },
            //添加标签的事件
            enterAdd(){
                this.$refs.addEditform.validate((valid) => {
                    if (valid) {
                        this.actor = ''
                        console.log(this.addEditform, "--------------");
                        this.$http.post("/store/labels/insertLabels", this.addEditform).then(resp => {
                            if (resp.data.code === 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '添加成功!'
                                });
                                this.loadLabels();
                            }
                        })
                        this.addEditform = {}
                        this.dialogVisible = false;
                    }
                })
            },
            //修改标签的事件
            enterEdit(){
                this.$refs.addEditform.validate((valid) => {
                    console.log(valid);
                    if (valid) {
                        this.actor = "";
                        console.log(this.addEditform, "-------------")
                        this.$http.put("/store/labels/updateLabels", this.addEditform).then(resp => {
                            if (resp.data.code === 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功!'
                                });
                                this.loadLabels();
                            }
                        })
                        this.addEditform = {}
                        this.dialogVisible = false;
                    }
                })
            },
            //删除标签的事件
            deleteLabel(num) {
                this.$confirm('是否删除该数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete("store/labels/deleteLabels/" + num).then(resp => {
                        if (resp.data.code === 2000) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.searchForm.currentPage = 1;
                            this.loadLabels();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            //修改页大小
            handleSizeChange(num) {
                this.pageSize = num;
                this.loadLabels();
            },
            //修改当前页
            handleCurrentChange(num) {
                this.currentPage = num;
                this.loadLabels();
            },
            //搜索店名
            searchStore() {
                this.currentPage= 1;
                this.loadLabels();
            },
            //刷新按钮
            flushedLabels() {
                this.searchForm.storeName='';
                this.loadLabels();
            },
        }
    }
</script>

<style scoped>

</style>