<template>
    <div>
        <!--搜索-->
        <el-row :gutter="20">
            <el-col :span="6">
                <el-input
                        v-model="searchForm.userName"
                        placeholder="请输入用户名"
                        size="big"
                ></el-input>
            </el-col>
            <el-col :span="18">
                <el-button
                        type="success"
                        size="big"
                        icon="el-icon-search"
                        @click="search"
                >搜索
                </el-button
                >
                <el-button
                        type="primary"
                        size="big"
                        icon="el-icon-refresh"
                        @click="refresh"
                >刷新
                </el-button
                >
            </el-col>
            <el-col :span="2"></el-col>
        </el-row>
        <el-row>
            <el-col :span="24"> &nbsp;</el-col>
        </el-row>

        <!-- table -->
        <el-table :data="tableData" style="width: 100%">
            <el-table-column prop="userId" label="用户Id" width="120" align="center">
            </el-table-column>
            <el-table-column prop="userName"  fixed label="用户名" width="120" align="center">
            </el-table-column>
            <el-table-column prop="nickName" label="用户昵称" width="120" align="center">
            </el-table-column>

            <el-table-column prop="avatar" label="头像" width="120" align="center">
                <template slot-scope="scope">
                    <el-image
                            style="width: 60px; height: 40px"
                            :src="scope.row.avatar"
                            align="center"
                    ></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="email" label="邮箱" width="150" align="center"></el-table-column>
            <el-table-column prop="phone" label="手机号码" width="120" align="center"></el-table-column>
            <!--formatter是el-table-column的一个属性，用来格式化内容（比如后台给你返0或1，你需要展示成“否”和“是”）-->
            <el-table-column prop="sex" label="性别" :formatter="getSex" width="70" align="center"></el-table-column>

            <el-table-column
                    prop="status"
                    label="账号状态"
                    :formatter="getStatus"
                    width="100"
                    align="center"
            >
            </el-table-column>
            <el-table-column prop="loginIp" label="最后登录id" width="150" align="center">
            </el-table-column>
            <el-table-column prop="registerTime" label="最后登陆时间" width="160" align="center">
            </el-table-column>
            <el-table-column prop="createBy" label="创建人" width="120" align="center">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160" align="center">
            </el-table-column>
            <el-table-column prop="updateBy" label="更新人" width="120" align="center">
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="160" align="center">
            </el-table-column>
            <el-table-column prop="remark" label="备注" width="120" align="center">
            </el-table-column>

        </el-table>

        <!--分页功能-->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchForm.current"
                :page-sizes="[1,2,5,10]"
                :page-size="searchForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>

</template>

<script>
    export default {
        name: "customer-management",
        data() {
            return {
                tableData: [],
                searchForm: {
                    //分页
                    current: 1,
                    pageSize: 5,
                    userName:''
                },
                total: 0,
            }


        },
        methods:{
            //分页查询所有用户信息
            loadData() {
                this.$http.post("vips/front/getAll", this.searchForm).then((response => {
                    if (response.data.code === 200) {
                        // if(this.searchForm.userName!=null||this.searchForm.userName!=""){
                        //     this.searchForm.current=1
                        // }
                        this.tableData = response.data.data.records;
                        this.total = response.data.data.total;
                    } else {
                        this.$message.error("查询发生错误");
                    }
                }))
            },
            search() {
                this.loadData();
            },
            //获取性别 把数字，转为字符
            getSex(row) {
                switch (row.sex) {
                    case "0":
                        return "男";
                    case "1":
                        return "女";
                    default:
                        return "保密";
                }
            },

            //获取账号状态，把数字，转为字符
            getStatus(row) {
                if (row.status === 0) {
                    return "停用";
                } else {
                    return "正常";
                }
            },
            //刷新方法
            refresh() {
                this.searchForm.userName=null
                this.searchForm.current=1;
                this.loadData();
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.searchForm.pageSize = val;
                this.searchForm.current=1;
                this.loadData();
            },

            //页码发生变化（第几页）
            handleCurrentChange(val) {
                this.searchForm.current = val;
                this.loadData();
            },
        },

        created() {
            this.loadData();
        }


    }
</script>

<style scoped>

</style>