<template>
    <div>
        <!--搜索-->
        <div id="searchData">
            <el-form :inline="true" :model="ProductVo" class="demo-form-inline" style="text-align: center">
                <el-form-item>
                    <el-input v-model="ProductVo.productName" placeholder="——请输入商品名称——"
                              class="center-text"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="search" icon="el-icon-search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addProductImage" icon="el-icon-circle-plus-outline">添加</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="refresh" icon="el-icon-refresh">刷新</el-button>
                </el-form-item>
            </el-form>

            <!-- table -->
            <el-table
                    :data="tableData"
                    style="width: 100%"
            >
                <el-table-column
                        prop="product.productName"
                        label="商品名称"
                        width="120"
                >
                </el-table-column>
                <el-table-column
                        prop="imageName"
                        label="图片名称"
                        width="120"
                >
                </el-table-column>
                <el-table-column
                        prop="imageurl"
                        label="图片路径"
                        width="120"
                >
                    <template slot-scope="scope">
                        <el-image
                                style="width: 60px; height: 40px"
                                :src="scope.row.imageurl"
                        ></el-image>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="user.userName"
                        label="创建人"
                        width="120"

                >
                </el-table-column>
                <el-table-column
                        prop="createtime"
                        label="创建时间"
                        width="160"
                >
                </el-table-column>
                <el-table-column
                        prop="description"
                        label="图片描述"
                        width="120"
                >
                </el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary"
                                   @click="updataProductImage(scope.row)"
                                   icon="el-icon-edit-outline">编辑
                        </el-button>
                        <el-button size="small" type="danger"
                                   @click="deleteproductImage(scope.row.id)"
                                   icon="el-icon-remove-outline">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--分页功能-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="current"
                    :page-sizes="[1,2,5,10]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>

            <!--添加或者更新的form表单-->
            <el-dialog
                    :visible.sync="dialogFormVisible"
                    :before-close="handleClose"
            >
                <el-form
                        ref="addEditform"
                        :rules="rules"
                        :model="addEditform"
                >
                    <el-form-item label="请选择商品" :label-width="formLabelWidth" prop="productId">
                        <div class="left">
                            <el-select
                                    v-model="addEditform.productId"
                                    clearable
                                    placeholder="请选择商品"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="item in optionsProduct"
                                        :label="item.productName"
                                        :value="item.productId"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                    <el-form-item
                            label="图片名称"
                            :label-width="formLabelWidth"
                            prop="imageName"
                    >
                        <el-input
                                v-model="addEditform.imageName"
                                autocomplete="off"
                                placeholder="图片名称"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="商品封面图片" :label-width="formLabelWidth" prop="imageurl">
                        <el-upload
                                class="avatar-uploader"
                                action="//192.168.28.124:7001/product/menu/uploadfile"
                                :headers="head"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="addEditform.imageurl" :src="addEditform.imageurl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>

                    <el-form-item
                            label="图片描述"
                            :label-width="formLabelWidth"
                    >
                        <el-input
                                v-model="addEditform.description"
                                autocomplete="off"
                                placeholder="图片描述"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="enterAdd">确定添加</el-button>
            <el-button type="primary" @click="enterEdit"  >确定编辑</el-button>
        </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProductImage",
        data(){
            return{
                ProductVo:{
                    productName:"",
                },
                tableData: [],
                current: 1, //当前页数
                pageSize: 5,//每页的数量
                total:0,
                dialogFormVisible: false,
                addEditform: {
                    imageName: '',
                    productId: '',
                    description: '',
                    imageurl: '',
                },
                rules: {
                    /*imageurl: [{required: true, message: '图片不能为空', trigger: 'change'}],
                    productId: [{required: true, message: '商品不能为空', trigger: 'change'}],*/
                    imageName: [{required: true, message: '图片名称不能为空', trigger: 'change'}],
                },
                optionsProduct: [],
                formLabelWidth: "120px",

                //图片参数
                head: '',
            }
        },
        created() {
            this.selectData();
            this.loadProductMsg();
            this.head = {
                token: sessionStorage.getItem("token")
            }
        },
        methods:{
            //图片上传的方法
            handleAvatarSuccess(res, file) {
                this.addEditform.imageurl = res.data;
            },
            beforeAvatarUpload(file) {
                const isImage = file.type.startsWith('image/');
                const isJPGorPNG = isImage && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/webp');
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPGorPNG) {
                    this.$message.error('上传头像图片只能是 JPG或者是PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPGorPNG && isLt2M;
            },
            //确认编辑方法
            enterEdit() {
                this.$refs.addEditform.validate((valid) => {
                    if (valid) {
                        this.$http.put("/product/image/updateProductImage", this.addEditform).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功!'
                                });
                                this.selectData();
                            }
                        })
                        this.addEditform = {}
                        this.dialogFormVisible = false;
                    }
                })
            },

            //编辑按钮
            updataProductImage(row) {
                this.dialogFormVisible = true;
                this.addEditform.id = row.id
                this.addEditform = row;
            },
            // //删除数据
            deleteproductImage(id) {
                this.$confirm('是否删除该数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete("/product/image/deleteProductImageById/"+id).then(resp => {
                        if (resp.data.code === 2000) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.current = 1;
                            this.selectData();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },

            //加载商品信息（用于添加按钮遍历下拉框）
            loadProductMsg() {
                this.$http.post("/product/menu/selectAllProduct").then(resp => {
                    this.optionsProduct = resp.data.data;
                })
            },
            //确认添加方法
            enterAdd() {
                this.$refs.addEditform.validate((valid) => {
                    if (valid) {
                        this.$http.post("/product/image/insertProductImage", this.addEditform).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '添加成功!'
                                });
                                this.selectData();
                            }
                        })
                        this.addEditform = {}
                        this.dialogFormVisible = false;
                    }
                });
            },
            handleClose() {
                this.dialogFormVisible = false;
                this.addEditform = {};
            },
            //添加按钮的方法
            addProductImage() {
                this.dialogFormVisible = true;
            },
            //刷新方法
            refresh() {
                this.ProductVo = {};
                this.current = 1;
                this.selectData();
            },
            //搜索
            search() {
                this.current=1;
                this.selectData();
            },
            //分页查询所有信息
            selectData() {
                this.$http.post("product/image/select/"+this.current+"/"+this.pageSize, this.ProductVo).then(result => {
                    console.log(result)
                    this.tableData = result.data.data.list;
                    this.total = result.data.data.total;
                })
            },

            //每页数量发生变化
            handleSizeChange(val) {
                this.pageSize = val;
                this.selectData();
            }
            ,
            //页码发生变化
            handleCurrentChange(val) {
                this.current = val;
                this.selectData();
            }
        }
    }
</script>

<style scoped>
    /*上传图片的样式*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        align-items: center;
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 100px;
        padding-top: 78px;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .left {
        /* 设置弹出框中输入框的宽度和位置 */
        width: 450px;
        text-align: left;
    }
</style>