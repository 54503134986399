import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from "@/views/store/Store.vue";
import Car from "@/views/vips/CarMenagement"
import FrontUser from "@/views/vips/CustomerManagement"
import UserIntegral from "@/views/vips/PointsManagement"
import UserDetail from "@/views/vips/DetailManagement"
import UserAddress from "@/views/vips/AddressManagement"
import Product from "@/views/product/Product.vue";
import ProductType from "@/views/product/ProductType.vue";
import ProductReview from "@/views/product/ProductReview.vue";
import StoreLabels from "@/views/store/StoreLabels.vue";
import Station from "@/views/store/StoreStation.vue";
import StoreEvaluate from "@/views/store/StoreEvaluate.vue";
import CouponCodes from "@/views/store/CouponCodes.vue"
import Menu from "@/views/menu/Menu.vue";
import RegisterEcharts from "@/views/sale/RegisterEcharts.vue";
import MerchantEcharts from "@/views/sale/MerchantEcharts.vue";
import MoneyEcharts from "@/views/sale/MoneyEcharts.vue";
import ProductImage from "@/views/product/ProductImage.vue";
import ProductExamine from "@/views/examine/ProductExamine.vue";
import PerInfo from "@/views/PerInfo.vue";


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/Login",
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import('../views/Login.vue'), // 使用懒加载
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/Home.vue"),

    children: [
        //系统首页

      {
        path: "/Store",
        name: "store",
        component: Store
      },
        //登录日志
      {
        path: "/About",
        name: "About",
        component: () => import("../views/about/About.vue"),
      },
      //操作日志
      {
        path: "/OperLog",
        name: "/OperLog",
        component: () => import("../views/about/OperLog.vue"),
      },
      //Vip管理-车辆管理
      {
        path: "/Car",
        name: "/Car",
        component: Car,
      },
      //Vip管理-客户管理
      {
        path: "/FrontUser",
        name: "/FrontUser",
        component: FrontUser,
      },
      //Vip管理-积分管理
      {
        path: "/UserIntegral",
        name: "/UserIntegral",
        component: UserIntegral,
      },
      //Vip管理-地址管理
      {
        path: "/UserAddress",
        name: "/UserAddress",
        component: UserAddress,
      },
      //Vip管理-明细管理
      {
        path: "/UserDetail",
        name: "/UserDetail",
        component: UserDetail,
      },
      //商品页面路由
      {
        path: "/Product",
        name: "product",
        component: Product,
      },
      //商品类型
      {
        path: "/ProductType",
        name: "/productType",
        component: ProductType,
      },
      //商品评价
      {
        path:"/ProductReview",
        name: "/productReview",
        component: ProductReview
      },

        //用户列表
      {
        path: "/UserList",
        name: "UserList",
        component: () => import("../views/user/UserList.vue"),
      },
        //角色列表
      {
        path: "/RoleList",
        name: "RoleList",
        component: () => import("../views/user/RoleList.vue"),
      },
      {
        path: "/OrderList",
        name: "OrderList",
        component: () => import("../views/order/OrderList.vue"),
      },
      //门店标签
      {
        path: "/StoreLabels",
        name: "/StoreLabels",
        component: StoreLabels

      },
      //菜单管理
      {
        path: "/Menu",
        name: "menu",
        component: Menu
      },
        //注册用户统计
      {
        path: "/RegisterEcharts",
        name: "registerEcharts",
        component: RegisterEcharts
      },
        //入驻商家统计
      {
        path: "/MerchantEcharts",
        name: "merchantEcharts",
        component: MerchantEcharts
      },
        //商家进账金额统计
      {
        path: "/MoneyEcharts",
        name: "MoneyEcharts",
        component: MoneyEcharts
      },
      //门店标签
      {
        path: "/Station",
        name: "/Station",
        component: Station
      },
      //门店评估
      {
        path: "/StoreEvaluate",
        name: "/StoreEvaluate",
        component: StoreEvaluate
      },
      //优惠券列表
      {
        path: "/CouponCodes",
        name: "/CouponCodes",
        component: CouponCodes
      },
      //商品评价
      {
        path:"/ProductImage",
        name: "/ProductImage",
        component: ProductImage
      },
      {
        path:"/ProductExamine",
        name: "/ProductExamine",
        component: ProductExamine
      },
      {
        path: "/Show",
        name: "Show",
        component: () => import("../views/Show.vue"),
      },
      {
        path: "/PerInfo",
        name: "PerInfo",
        component: PerInfo,
      }


    ],
  },

  //登录页面路由
  {
    path: "/Login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
]

const router = new VueRouter({
  routes
})

export default router
