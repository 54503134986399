<template>
    <div id="app">
        <div id="searchData">
            <el-form :inline="true" :model="ProductVo" class="demo-form-inline" style="text-align: center">
                <el-form-item>
                    <el-input v-model="ProductVo.productName"  placeholder="——请按照商品名称搜索——"
                              class="center-text"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="success"  icon="el-icon-search" @click="search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addProduct" icon="el-icon-circle-plus-outline">添加</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="refresh" icon="el-icon-refresh">刷新</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- table -->
        <el-table :data="tableData" style="width: 100%" height="450">
            <!-- 序号 -->
            <el-table-column type="index" :index="indexMethod"></el-table-column>
            <el-table-column prop="productType.name" label="商品类型" width="120">
            </el-table-column>
            <el-table-column prop="productName" label="商品名称" width="120">
            </el-table-column>
            <el-table-column prop="picture" label="头像" width="100">
                <template v-slot="scope">
                    <el-image style="width: 60px; height: 40px" :src="scope.row.picture"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="newPrice" label="现价(元)" width="120">
            </el-table-column>
            <el-table-column prop="loadPrice" label="原价(元)" width="120">
            </el-table-column>
            <el-table-column prop="integral" label="积分" width="100">
            </el-table-column>
            <el-table-column prop="monthlySales" label="月销售额(元)" width="120">
            </el-table-column>
            <el-table-column prop="freight" label="运费(元)" width="100">
            </el-table-column>
            <el-table-column prop="store.storeName" label="门店" width="100">
            </el-table-column>
            <el-table-column prop="isline" label="是否上线" :formatter="isline" width="100">
            </el-table-column>
            <el-table-column prop="prompt" label="温馨提示" width="300">
            </el-table-column>
            <el-table-column prop="productDetails" label="商品详情" width="150" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="user.userName" label="创建人" width="120" >
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160">
            </el-table-column>
            <el-table-column prop="user.userName" label="更新人" width="120" >
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" width="160">
            </el-table-column>
            <el-table-column prop="remark" label="备注" width="120">
            </el-table-column>
            <el-table-column label="操作" width="200" fixed="right">
                <template slot-scope="scope">
                    <el-button size="small" type="primary"
                               @click="updataProduct(scope.row)"
                               icon="el-icon-edit-outline">编辑</el-button>
                    <el-button size="small" type="danger" @click="deleteproduct(scope.row.productId)"
                               icon="el-icon-remove-outline">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <!--分页功能-->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="current"
                :page-sizes="[1,2,5,10]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>

        <!--添加或者更新的form表单-->
        <div id="dialog">
            <!--添加和编辑的对话框-->
            <el-dialog :visible.sync="dialogFormVisible"
                       :before-close="handleClose">
                <el-form ref="addEditform" :rules="rules" :model="addEditform">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="商品类型" :label-width="formLabelWidth" prop="proTypeId">
                                <div class="left">
                                    <el-select
                                            v-model="addEditform.proTypeId"
                                            clearable
                                            placeholder="请选择商品类型"
                                            style="width: 100%"
                                    >
                                        <el-option
                                                v-for="item in optionsProductType"
                                                :label="item.name"
                                                :value="item.commentId"
                                        >
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="所属门店" :label-width="formLabelWidth" prop="storeId">
                        <div class="left">
                            <el-select
                                    v-model="addEditform.storeId"
                                    clearable
                                    placeholder="请选择所属门店"
                                    style="width: 100%"
                            >
                                <el-option
                                        v-for="item in stores"
                                        :label="item.storeName"
                                        :value="item.storeId"
                                >
                                </el-option>
                            </el-select>
                        </div>
                    </el-form-item>
                    <el-form-item label="商品名称" :label-width="formLabelWidth" prop="productName">
                        <div class="left">
                            <el-input
                                    v-model="addEditform.productName"
                                    autocomplete="off"
                                    placeholder="商品名称"
                            ></el-input>
                        </div>
                    </el-form-item>

                    <el-form-item label="商品封面图片" :label-width="formLabelWidth" prop="picture">
                        <el-upload
                                class="avatar-uploader"
                                action="//192.168.28.124:7001/product/menu/uploadfile"
                                :headers="head"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                                :before-upload="beforeAvatarUpload">
                            <img v-if="addEditform.picture" :src="addEditform.picture" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="积分" :label-width="formLabelWidth" prop="integral">
                                <div class="left">
                                    <el-input
                                            v-model="addEditform.integral"
                                            autocomplete="off"
                                            placeholder="设置获取商品所需积分"
                                    ></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="是否启用积分" :label-width="formLabelWidth">
                                <div class="left">
                                    <el-switch
                                            v-model="addEditform.integralEnabled"
                                            class="isShow"
                                            :active-value="'0'"
                                            :inactive-value="'1'"
                                            active-color="#13ce66"
                                            inactive-color="#ff4949"
                                    >
                                    </el-switch>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="原价(元)" :label-width="formLabelWidth" prop="loadPrice">
                                <div class="left">
                                    <el-input
                                            v-model="addEditform.loadPrice"
                                            autocomplete="off"
                                            placeholder="请输入该商品原价"
                                    ></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-form-item label="所需金额(元)" :label-width="formLabelWidth" prop="newPrice">
                                <div class="left">
                                    <el-input
                                            v-model="addEditform.newPrice"
                                            autocomplete="off"
                                            placeholder="金额(元)"
                                    ></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="运费(元)" :label-width="formLabelWidth" prop="freight">
                                <div class="left">
                                    <el-input
                                            v-model="addEditform.freight"
                                            autocomplete="off"
                                            placeholder="设置该商品的所需运费"
                                    ></el-input>
                                </div>
                            </el-form-item>

                        </el-col>

                    </el-row>

                    <el-form-item label="温馨提示" :label-width="formLabelWidth">
                        <div class="left">
                            <el-input
                                    v-model="addEditform.prompt"
                                    autocomplete="off"
                                    placeholder="请编辑该商品的提示信息"
                            ></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item label="商品备注" :label-width="formLabelWidth">
                        <div class="left">
                            <el-input
                                    v-model="addEditform.remark"
                                    autocomplete="off"
                                    placeholder="请输入备注"
                            ></el-input>
                        </div>
                    </el-form-item>
                    <!--富文本编辑器-->
                    <el-form-item label="商品详情" :label-width="formLabelWidth">
                        <div class="left">
                            <el-input
                                    v-model="addEditform.productDetails"
                                    autocomplete="off"
                                    placeholder="请输入详情"
                            ></el-input>
                        </div>
                        <!--<div id="editor" v-model="addEditform.productDetails"></div>-->
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="enterAdd">确定添加</el-button>
                    <el-button type="primary" @click="enterEdit">确定编辑</el-button>
                  </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>

    export default {
        name: "Product",
        data() {
            return {
                tableData: [],
                optionsProduct: [],
                current: 1, //当前页数
                pageSize: 5,//每页的数量
                searchForm:{},
                total: 0,
                dialogFormVisible: false,
                formLabelWidth: "120px",
                addEditform: {
                    productId: '',
                    storeId: '',
                    picture: '',
                    proTypeId: '',
                    commentId: '',
                    productName: '',
                    integral: '',
                    integralEnabled: '',
                    loadPrice: '',
                    newPrice: '',
                    freight: '',
                    isFreightShipping: '',
                    prompt: '',
                    remark: '',
                },
                //获取修改商品类型
                optionsProductType: [],
                rules: {
                    storeId: [{required: true, message: '商店不能为空', trigger: 'change'}],
                    /*picture: [{required: true, message: '图片不能为空', trigger: 'change'}],*/
                    proTypeId: [{required: true, message: '商品类型不能为空', trigger: 'change'}],
                    productName: [{required: true, message: '商品名称不能为空', trigger: 'change'}],
                    integral: [{required: true, message: '积分不能为空', trigger: 'change'}],
                    loadPrice: [{required: true, message: '原价不能为空', trigger: 'change'}],
                    newPrice: [{required: true, message: '现价不能为空', trigger: 'change'}],
                    freight: [{required: true, message: '运费不能为空', trigger: 'change'}],
                },
                stores: [],
                ProductVo:{
                    productId:"",
                    productName:"",
                },
                Options: [],
                //图片参数
                head: '',
            }
        },
        created() {
            this.loadProduct();
            this.loadProductTypeMsg();
            this.loadStoreMsg();
            this.head = {
                token: sessionStorage.getItem("token")
            }
        },
        methods:{
            //图片上传的方法
            handleAvatarSuccess(res, file) {
                this.addEditform.picture = res.data;

            },
            beforeAvatarUpload(file) {
                const isImage = file.type.startsWith('image/');
                const isJPGorPNG = isImage && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/webp');
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPGorPNG) {
                    this.$message.error('上传头像图片只能是 JPG或者是PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPGorPNG && isLt2M;
            },

            /*//从查到的用户信息中查询对应的创建者姓名
            createFormatter(row) {
                for (let i = 0; i < this.Options.length; i++) {
                    if (row.createId === this.Options[i].userId) {
                        return this.Options[i].userName;
                    }
                }
            }
            ,*/
            /*//从查到的用户信息中查询对应的修改者姓名
            updateFormatter(row) {
                for (let i = 0; i < this.Options.length; i++) {
                    if (row.updateId === this.Options[i].userId) {
                        return this.Options[i].userName;
                    }
                }
            },*/
            //编辑按钮
            updataProduct(row) {
                this.dialogFormVisible = true;
                this.addEditform.productId = row.productId
                this.addEditform = row;
                this.addEditform.productDetails = row.productDetails;
            },
            //确认编辑方法
            enterEdit() {
                this.$refs.addEditform.validate((valid) => {
                    console.log(valid);
                    if (valid) {
                        this.$http.put("/product/menu/updateProduct", this.addEditform).then(resp => {
                            console.log(resp)
                            if (resp.data.code == 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功!'
                                });
                                this.loadProduct();
                            }
                        })
                        this.addEditform = {}
                        this.dialogFormVisible = false;
                    }
                })
            },

            //刷新
            refresh(){
                this.ProductVo = {};
                this.current = 1;
                this.loadProduct();
            },
            //删除数据
            deleteproduct(productId) {
                this.$confirm('是否删除该数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete("/product/menu/deleteByProductId/"+productId).then(resp => {
                        if (resp.data.code === 2000) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.current = 1;
                            this.loadProduct();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            search(){
                this.current=1;
                this.loadProduct();
            },
            //确认添加方法
            enterAdd() {
                this.$refs.addEditform.validate((valid) => {
                    if (valid) {
                        this.$http.post("/product/menu/insertProduct", this.addEditform).then(resp => {
                            console.log("~~~~~~~~~~~~`~~~~~~~~`")
                            console.log(this.addEditform)
                            if (resp.data.code == 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '添加成功!',
                                });
                                this.loadProduct();
                            }
                        })
                        this.addEditform = {}
                        this.dialogFormVisible = false;
                    }
                })
            },
            //加载所有店铺信息
            loadStoreMsg() {
                this.$http.get("/store/api/selectStoreByUserId").then(resp => {
                    this.stores = resp.data.data;
                })
            },
            //加载商品类型信息
            loadProductTypeMsg() {
                this.$http.post("/product/type/selectProductType").then(resp => {
                    this.optionsProductType = resp.data.data;
                })
            },
            //对话框的叉
            handleClose() {
                this.dialogFormVisible = false;
            },
            //添加按钮
            addProduct(){
                this.dialogFormVisible = true;
            },
            //是否上线
            isline(row) {
                return row.isline == 0 ? "是" : "否";
            },
            //显示索引
            indexMethod(index) {
                return index + 1;
            },
            loadProduct(){
                this.$http.post("/product/menu/info/"+this.current+"/"+this.pageSize,this.ProductVo).then(resp => {
                    console.log(resp)
                    this.tableData = resp.data.data.list;
                    this.total = resp.data.data.total;
                })
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.current=1;
                this.pageSize = val;
                this.loadProduct();
            }
            ,
            //页码发生变化
            handleCurrentChange(val) {
                this.current = val;
                this.loadProduct();
            }
        }
    }
</script>

<style scoped>
    /*上传图片的样式*/
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        align-items: center;
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 100px;
        padding-top: 78px;
    }

    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }

    .left {
        /* 设置弹出框中输入框的宽度和位置 */
        width: 87%;
        text-align: left;
    }
</style>