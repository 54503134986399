<template>
    <div style="width: 800px">
        <h2>个人信息</h2>
        <el-form :model="perForm" :label-width="formLabelWidth" class="demo-ruleForm" :rules="rules">
            <el-form-item label="用户账号" prop="userName">
                <el-input v-model="perForm.userName"></el-input>
            </el-form-item>
            <el-form-item label="用户密码" :label-width="formLabelWidth" prop="password">
                <el-input v-model="perForm.password" type="password" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="头像" :label-width="formLabelWidth" prop="avatar">
                <el-upload
                        class="avatar-uploader"
                        action="//192.168.0.103:8081/upload/upload"
                        :show-file-list="false"
                        name="file"
                        :headers="head"
                        :on-success="handleSuccess"
                        :before-upload="beforeAvatarUpload"
                        ref="upload"


                >
                    <img
                            v-if="perForm.avatar"
                            :src="perForm.avatar"
                            class="avatar"

                            alt=""
                    />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="手机号码" :label-width="formLabelWidth">
                <el-input v-model="perForm.phonenumber" autocomplete="off" ></el-input>
            </el-form-item>
            <el-form-item label="用户邮箱" :label-width="formLabelWidth" prop="email">
                <el-input v-model="perForm.email"></el-input>
            </el-form-item>
            <el-form-item label="用户性别" :label-width="formLabelWidth">
                <el-radio-group v-model="perForm.sex">
                    <el-radio label="0">男</el-radio>
                    <el-radio label="1">女</el-radio>
                    <el-radio label="2">保密</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm">立即修改</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "PerInfo",
        data(){
          return{
              //添加的图片地址
              imageUrl: "",
              perForm: {
                  userName: '',
                  password: '',
                  email: '',
                  avatar: '', // 头像地址
                  phonenumber: '',
                  sex: ''
              },
              formLabelWidth: "120px",
              //表单验证
              rules: {

                  userName: [
                      {required: true, message: "长度在 3 到 10 个字符", trigger: "blur"},
                      {
                          min: 3,
                          max: 10,
                          message: "长度在 3 到 10 个字符",
                          trigger: "blur",
                      },
                  ],
                  password: [
                      {required: true, message: "请输入密码", trigger: "blur"},
                      {min: 6, max: 10, message: "由6-10字母和数字组成", trigger: "blur"},
                      {
                          required: true,
                          pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,10}$/,
                          message: "必须由6-10字母和数字组成",
                          trigger: "blur",
                      },
                  ],
              },
          }
        },
        created() {
            this.loadAllInfo();
            this.head = {
                token: sessionStorage.getItem("token")
            }
        },
        methods:{
            loadAllInfo() {
                this.$http.get("/backUser/info").then(response => {
                    this.perForm = response.data.data;
                }).catch(error => console.error(error));
            },
            submitForm() {
                this.$http.post("/backUser/update", this.perForm).then(response => {
                    if (response.data.code === 200) {
                        this.$message.success("修改成功");
                    } else {
                        this.$message.error("修改失败");
                    }
                }).catch(error => console.error(error));
            },
            //添加文件上传成功后的回调函数
            handleSuccess(response, file) {
              console.log(response.data)
                this.perForm.avatar = response.data
            },

            //校验文件大小 格式
            beforeAvatarUpload(file) {
                const isImage = file.type.startsWith('image/');
                const isJPGorPNG = isImage && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/webp');
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPGorPNG) {
                    this.$message.error('上传头像图片只能是 JPG或者是PNG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPGorPNG && isLt2M;
            },
        },
    }
</script>

<style scoped>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        /* margin-top: -100px;
         margin-left: 120px;*/
        /*position: relative;*/
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }

    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }
</style>
