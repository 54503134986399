<template>
    <div id="app">
        <div id="searchData">
            <el-form :inline="true" :model="ProductVo" class="demo-form-inline" style="text-align: center">
                <el-form-item>
                    <el-input v-model="ProductVo.productName" placeholder="——请按照商品名称搜索——"
                              class="center-text"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="search"  icon="el-icon-search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="refresh" icon="el-icon-refresh">刷新</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="tableData" style="width: 100%">
            <el-table-column width="100" prop="product.productName" label="商品"></el-table-column>
            <el-table-column width="150" prop="frontUser.userName" label="用户" ></el-table-column>
            <el-table-column width="120" prop="content" label="评价内容"></el-table-column>
            <el-table-column width="100" prop="rating" label="评分"></el-table-column>
            <el-table-column width="200" prop="createTime" label="创建时间"></el-table-column>
        </el-table>
        <!--分页功能-->
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="current"
                :page-sizes="[1,2,5,10]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "ProductReview",
        data() {
            return {
                tableData: [],
                current: 1, //当前页数
                pageSize: 5,//每页的数量
                searchForm:{},
                total:0,
                ProductVo:{
                    productName:"",
                },

            }
        },
        created() {
            this.selectData()
        },
        methods:{
            //刷新方法
            refresh() {
                this.ProductVo = {};
                this.current = 1;
                this.selectData();
            },
            //搜索
            search() {
                this.current=1;
                this.selectData();
            },
            //分页查询所有信息
            selectData() {
                this.$http.post("product/review/select/"+this.current+"/"+this.pageSize,this.ProductVo).then(result => {
                    console.log("@@@@@@2", result)
                    this.tableData = result.data.data.list;
                    this.total = result.data.data.total;
                })
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.pageSize = val;
                this.selectData();
            }
            ,
            //页码发生变化
            handleCurrentChange(val) {
                this.current = val;
                this.selectData();
            }
        }
    }
</script>

<style scoped>

</style>