<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {

  }
}
</script>

<style>
.el_popover_class{
  max-height: 440px !important;
  overflow-y: auto !important;
}
</style>
