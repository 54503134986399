<template>
    <div>
        <div id="searchData">
            <el-form :inline="true" :model="ProductTypeVo" class="demo-form-inline" style="text-align: center">
                <el-form-item>
                    <el-input v-model="ProductTypeVo.name" placeholder="——请输入商品类型——"
                              class="center-text"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="success" @click="search" icon="el-icon-search">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addProductType" icon="el-icon-circle-plus-outline">添加</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="refresh" icon="el-icon-refresh">刷新</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" style="width: 100%">
                <!-- 序号 -->
                <el-table-column type="index" :index="indexMethod"></el-table-column>
                <el-table-column prop="name" label="商品类型" width="120"></el-table-column>
                <el-table-column prop="description" label="商品类型描述" width="200"></el-table-column>
                <el-table-column prop="isedit" label="是否允许修改" width="120">
                    <template slot-scope="scope">
                        <el-switch
                                v-model="scope.row.isedit"
                                class="isShow"
                                :active-value="'1'"
                                :inactive-value="'0'"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                @change="changeIsedit(scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="user.userName" label="创建人" width="120" ></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="160"></el-table-column>
                <el-table-column prop="user.userName" label="更新人" width="120" ></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" width="160"></el-table-column>
                <el-table-column label="操作" width="200" fixed="right">
                    <template slot-scope="scope">
                        <!-- //开关组件更新数据库状态 是否允许被修改-->
                        <el-button size="small" type="primary" icon="el-icon-edit-outline"
                                   @click="updataProductType(scope.row)"
                                   :disabled="scope.row.isedit!=='1'">编辑
                        </el-button>
                        <el-button size="small" type="danger"
                                   @click="deleteproductType(scope.row.commentId)"
                                   icon="el-icon-remove-outline">删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--分页功能-->
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="current"
                    :page-sizes="[1,2,5,10]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
            </el-pagination>

            <!--添加或者更新的form表单-->
            <el-dialog  :visible.sync="dialogFormVisible">
                <el-form ref="addEditform" :rules="rules" :model="addEditform">
                    <el-form-item prop="name" label="商品类型" :label-width="formLabelWidth">
                        <el-input v-model="addEditform.name" autocomplete="off" placeholder="商品类型"></el-input>
                    </el-form-item>
                    <el-form-item label="商品类型描述" :label-width="formLabelWidth">
                        <el-input v-model="addEditform.description" autocomplete="off" placeholder="商品类型描述"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="enterAdd">确定添加</el-button>
            <el-button type="primary" @click="enterEdit">确定编辑</el-button>
        </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ProductType",
        data() {
            return {
                searchForm:{},
                tableData: [],
                current: 1, //当前页数
                pageSize: 5,//每页的数量
                total: 0,
                ProductTypeVo:{
                    name:"",
                },
                dialogFormVisible: false,
                addEditform: {
                    name: '',
                    description: '',
                },
                formLabelWidth: "120px",
                rules: {
                    name: [{required: true, message: '商品类型不能为空', trigger: 'change'}],
                    // stationName: [{required: true, message: '库不能为空', trigger: 'change'}],
                },
                Options: [],
            }
        },
        created() {
            this.selectData();
        },
        methods:{
            //确认编辑方法
            enterEdit() {
                this.$refs.addEditform.validate((valid) => {
                    if (valid) {
                         console.log(this.addEditform, "-------------")
                        this.$http.put("product/type/updateProductType", this.addEditform).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '更新成功!'
                                });
                                this.selectData();
                            }
                        })
                        this.addEditform = {}
                        this.dialogFormVisible = false;
                    }
                })
            },
            //编辑按钮
            updataProductType(row) {
                // console.log(row);
                this.dialogFormVisible = true;
                this.addEditform.commentId = row.commentId
                this.addEditform = row;
            },
            //删除数据
            deleteproductType(commentId) {
                 console.log(commentId,"--------------------")
                this.$confirm('是否删除该数据？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete("/product/type/deleteProductTypeById/"+commentId).then(resp => {
                        if (resp.data.code === 2000) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.current = 1;
                            this.selectData();
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            //确认添加方法
            enterAdd() {
                this.$refs.addEditform.validate((valid) => {
                    console.log(valid);
                    if (valid) {
                        // console.log(this.addEditform, "-----添加---------");
                        this.$http.post("/product/type/insertProductType", this.addEditform).then(resp => {
                            if (resp.data.code == 2000) {
                                this.$message({
                                    type: 'success',
                                    message: '添加成功!'
                                });
                                this.selectData();
                            }
                        })
                        this.addEditform = {}
                        this.dialogFormVisible = false;
                    }
                });
            },
            //添加按钮的方法
            addProductType() {
                this.dialogFormVisible = true;
            },
            //刷新
            refresh(){
                this.ProductTypeVo = {};
                this.current = 1;
                this.selectData();
            },
            //搜索
            search() {
                this.current=1;
                this.selectData();
            },
            //显示索引
            indexMethod(index) {
                return index + 1;
            },
            selectData() {
                this.$http.post("product/type/selectProductType/"+this.current+"/"+this.pageSize,this.ProductTypeVo).then(result => {
                    console.log(result)
                    this.tableData = result.data.data.list;
                    this.total = result.data.data.total;
                })
            },
            //每页数量发生变化
            handleSizeChange(val) {
                this.pageSize = val;
                this.selectData();
            }
            ,
            //页码发生变化
            handleCurrentChange(val) {
                this.current = val;
                this.selectData();
            }
        }
    }
</script>

<style scoped>

</style>